
import "./style.css";
import Base from "./views/Base";

function App() {
  return (

    <div className="App">
      <Base />
    </div>

  );
}

export default App;
